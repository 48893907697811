<template>
  <!-- 左侧详情 -->
  <div class="Margin">
    <div class="crumbs"><a href="/EnterpriseCenter/EnterpriseHome">企业中心</a> > 投诉详情</div>
    <!-- 投诉列表 -->
    <div class="details_list">
      <div class="details_list_top">
        <div class="details_list_top_title">
          {{ details_list.statement_title }}
        </div>
        <!-- <div class="Related_complaints" @click="Related_complaints(details_list.complaint_business_id)">相关投诉 ></div> -->
      </div>
      <div class="details_list_content">
        <div class="details_list_content_left">
          <div class="details_list_content_left_ct" style="margin-top: 10px">
            <span style="color: #1f72d9; margin-right: 3px">[诉案编号]</span>
            {{ details_list.complaint_number }}
          </div>
          <div class="details_list_content_left_ct">
            <span style="color: #1f72d9; margin-right: 3px">[投诉对象]</span>
            <span @click="Go_HotBrands(details_list_name.id)" style="cursor: pointer;color:rgb(254, 144, 37)">{{ details_list_name.name}}</span>
          </div>
          <div class="details_list_content_left_ct">
            <span style="color: #1f72d9; margin-right: 3px">[投诉缘由]</span>
            {{ details_list.complaint_reason }}
          </div>
          <div class="details_list_content_left_ct">
            <span style="color: #1f72d9; margin-right: 3px">[投诉诉求]</span>
            {{ details_list.complaint_appeal }}
          </div>
          <div class="details_list_content_left_ct">
            <span style="color: #1f72d9; margin-right: 3px">[涉诉金额]</span>
            {{ details_list.complaint_price }}元
          </div>
        </div>
        <div class="details_list_content_right">
          <img v-if="details_list.status == 2" src="@/assets/img/yiwancheng.png" alt="" />
          <img v-if="details_list.status == 5" src="@/assets/img/weitongguo.png" alt="" />
          <img v-if="details_list.status == 4" src="@/assets/img/yichexiao.png" alt="" />
          <img v-if="details_list.status == 0" src="@/assets/img/shenhezhong.png" alt="" />
          <img v-if="details_list.status == 1" src="@/assets/img/chulizhong.png" alt="" />
        </div>
      </div>
      <div class="details_list_bottom">
        <div class="details_list_bottom_box">
          <img v-if="collection" @click="toCollect" :src="isgive_ups == 0?z_img:z_img1" alt="" />
          <img v-else @click="toCollect" :src="isgive_ups == 1?z_img:z_img1" alt="" />
          <div>{{details_list.like_num}}</div>
        </div>
        <div class="details_list_bottom_box">
          <img src="@/assets/img/pinglun_icon.png" alt="" />
          <div>{{details_list.comment_num}}</div>
        </div>
        <div class="details_list_bottom_box">
          <img v-if="collection1" @click="toCollect1" :src="isCollections == 0?c_img:c_img1" alt="" />
          <img v-else @click="toCollect1" :src="isCollections == 1?c_img:c_img1" alt="" />
          <div>111</div>
        </div>
        <div class="fenxiang">
          <img src="@/assets/img/fenxiang.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 投诉详情 -->
    <div class="Complaint_details">
      <div class="Complaint_details_title">投诉详情</div>
      <div class="Complaint_details_content">
        {{details_list.complaint_details}}
      </div>
      <div class="Material_Science">
          <div class="Material_Science_top">
            <span>证据材料</span>
            <img src="@/assets/img/xingzhuang7.png" alt="">
          </div>
          <div class="Material_Science_bottom">
                <img :src="item" alt="" v-for="(item,index) in evidential_materials" :key="index" class="Material_Science_bottom_img">
          </div>
      </div>
    </div>
    <!-- 投诉进度 -->
    <div class="Complaint_progress">
      <div class="Complaint_progress_title">投诉进度</div>
    </div>
    <div class="Complaint_progress_bottom">
      <div class="Complaint_progress_bottom_box" v-if="C_Progress.uid==user_uid">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_opinion">
          <div style="color: #999999;margin-right:20px;">您是否同意商家的处理意见？</div>
          <el-radio-group v-model="radio">
            <el-radio label="1">认可</el-radio>
            <el-radio label="2">不认可</el-radio>
          </el-radio-group>
        </div>
        <input type="text" class="opinion_send_ipt" v-model="opinion_ipt">
        <div class="opinion_send_box">
          <el-radio-group v-model="radio1">
            <el-radio label="0">公开</el-radio>
            <el-radio label="1">不公开</el-radio>
          </el-radio-group>
          <div class="opinion_send" @click="opinion_send">发送</div>
        </div>
      </div>
      <div class="Complaint_progress_bottom_box" v-if="C_Progress.evaluate_time!=0">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{C_Progress.evaluate_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">[{{C_Progress.nickname}}] <span style="color:#FE9025;">评价</span>
        </div>
        <div class="Complaint_progress_bottom_box_xing">对商家处理效率和结果是否满意？
          <img v-if="C_Progress.efficiency_results_level==1" src="@/assets/img/1xing.png" alt="">
          <img v-if="C_Progress.efficiency_results_level==2" src="@/assets/img/2xing.png" alt="">
          <img v-if="C_Progress.efficiency_results_level==3" src="@/assets/img/3xing.png" alt="">
          <img v-if="C_Progress.efficiency_results_level==4" src="@/assets/img/4xing.png" alt="">
          <img v-if="C_Progress.efficiency_results_level==5" src="@/assets/img/5xing.png" alt="">
        </div>
        <div class="Complaint_progress_bottom_box_xing">对商家处理效率和结果是否满意？
          <img v-if="C_Progress.service_level==1" src="@/assets/img/1xing.png" alt="">
          <img v-if="C_Progress.service_level==2" src="@/assets/img/2xing.png" alt="">
          <img v-if="C_Progress.service_level==3" src="@/assets/img/3xing.png" alt="">
          <img v-if="C_Progress.service_level==4" src="@/assets/img/4xing.png" alt="">
          <img v-if="C_Progress.service_level==5" src="@/assets/img/5xing.png" alt="">
        </div>
        <div class="Complaint_progress_bottom_box_title">{{C_Progress.opinion_info}}</div>
      </div>
      <div class="Complaint_progress_bottom_box" v-if="C_Progress.complete_time!=0">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{C_Progress.complete_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">[{{C_Progress.nickname}}] <span
            style="color:#FE9025;">确认完成</span></div>
      </div>
      <div class="Complaint_progress_bottom_box" v-for="(item,index) in A_Progress" :key="index">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{item.create_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name" v-if="item.uid !=null">[{{item.nickname}}] <span
            style="color:#FE9025;">回复</span></div>
        <div class="Complaint_progress_bottom_box_name" v-if="item.business_uid !=null">[{{item.business_nickname}}客服]
          <span style="color:#FE9025;">回复</span>
        </div>
        <div class="Complaint_progress_bottom_box_name" v-if="item.is_open ==0">{{item.content}}</div>
        <div class="Complaint_progress_bottom_box_name" v-else>该内容不公开显示</div>
      </div>
      <!-- <div class="Complaint_progress_bottom_box" v-for="(items,indexs) in C_Progress.publish" :key="indexs" v-show="items.business_uid !=null">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{items.create_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">[{{items.business_nickname}}客服] <span style="color:#FE9025;">回复</span></div>
        <div class="Complaint_progress_bottom_box_name">{{items.content}}</div>
      </div> -->
      <div class="Complaint_progress_bottom_box" v-if="C_Progress.adopt_time!=0">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{C_Progress.adopt_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">已分配商家[{{ details_list_name.name}}] <span
            style="color:#FE9025;">商家处理中</span></div>
      </div>
      <div class="Complaint_progress_bottom_box">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{C_Progress.create_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">鹰眼投诉平台<span style="color:#FE9025;">审核中</span></div>
      </div>
      <div class="Complaint_progress_bottom_box">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{C_Progress.create_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">{{C_Progress.nickname}}<span>发起投诉</span></div>
      </div>
    </div>
    <!-- 评论 -->
    <div class="comment">
      <input type="text" class="comment_ipt" v-model="msg" @input="descInput" maxlength="200" placeholder="说点什么..." />
      <div class="Reply_box">
        <div class="Enter_the_number">{{ remnant }}/200</div>
        <div v-if="msg.length == 0" class="Reply">回复</div>
        <div class="Reply1" @click="Reply" v-else>回复</div>
      </div>
    </div>
    <!-- 最新评论 -->
    <div class="Latest_comments">
      <div class="Latest_comments_title">最新评论</div>
      <div v-if="tokens ==null" class="login_zs" @click="G_login">登录后查看</div>
      <div class="Latest_comments_b" v-for="(item,index) in comment_lists" :key="index">
        <div class="Latest_comments_list">
          <div class="Latest_comments_list_left">
            <img :src="item.headimgurl" alt="" />
          </div>
          <div class="Latest_comments_list_content">
            <div class="Latest_comments_list_content_top">
              <div class="names">{{item.userinfo}}</div>
              <div class="datas">{{item.add_time}}</div>
            </div>
            <div class="Latest_comments_list_content_bottom">
              {{item.content}}
            </div>
          </div>
          <div class="Latest_comments_list_right">
            <img src="@/assets/img/pinglun_icon.png" @click="Eject_Comments(index,item.id,item.userinfo)" alt="" />
            <img src="@/assets/img/dianzan_icon.png" alt="" />
            <div>{{item.like_num}}</div>
          </div>
        </div>
        <div v-show="hf_cm == index" class="hf_comment">
          <input type="text" class="hf_comment_ipt" v-model="msgs" @input="descInputs" maxlength="200"
            :placeholder="'回复:'+item.userinfo" />
          <div class="hf_Reply_box">
            <div class="hf_Enter_the_number">{{ remnants }}/200</div>
            <div v-if="msgs.length == 0" class="hf_Reply">回复</div>
            <div class="hf_Reply1" v-else @click="Hf_Comments(item.id)">回复</div>
          </div>
        </div>
        <!-- 二级 -->
        <div v-for="(item,indexs) in item.childrenList" :key="indexs">
          <div class="Latest_comments_list two_Latest_comments" style="width:750px;">
            <div class="Latest_comments_list_left">
              <img :src="item.headimgurl" alt="" />
            </div>
            <div class="Latest_comments_list_content">
              <div class="Latest_comments_list_content_top">
                <div class="names">{{item.userinfo}}</div>
                <div class="datas">{{item.add_time}}</div>
              </div>
              <div class="Latest_comments_list_content_bottom">
                {{item.content}}
              </div>
            </div>
            <div class="Latest_comments_list_right">
              <!-- <img src="../assets/img/pinglun_icon.png" @click="Eject_Comments1(indexs,item.id,item.userinfo,index)" alt="" /> -->
              <!-- <img src="../assets/img/dianzan_icon.png" alt="" /> -->
              <!-- <div>{{item.like_num}}</div> -->
            </div>
          </div>
          <!-- <div v-show="hf_cm1 == indexs" class="hf_comment">
            <input type="text" class="hf_comment_ipt" v-model="msgs1" @input="descInputs" maxlength="200"
              :placeholder="'回复:'+item.userinfo" />
            <div class="hf_Reply_box">
              <div class="hf_Enter_the_number">{{ remnants1 }}/200</div>
              <div v-if="msgs1.length == 0" class="hf_Reply">回复</div>
              <div class="hf_Reply1" v-else @click="Hf_Comments1(item.id)">回复</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  complaint_details,
  comment_list,
  instantly_message,
  give_up,
  give_ups,
  Collection,
  Progress,
} from "../../api/api";
import Axios from "axios";
import md5 from 'js-md5';
export default {
   metaInfo(){
     return{
        title: '品牌列表',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  inject: ["reload"],
  name: "DetailsLeft",
  data() {
    return {
      tokens: "",
      Xq_Id: "", //上个页面传过来的id
      msg: "",
      msgs: "",
      remnant: 0,
      remnants: 0,
      hf_cm: -1,
      details_list: "",
      details_list_name: "",
      comment_lists: [], //评论列表展示
      collection: true, //是否点赞
      isgive_ups: "", //点赞状态
      z_img: require("@/assets/img/dianzan_icon.png"),
      z_img1: require("@/assets/img/dianzan_icon1.png"),
      collection1: true, //是否收藏
      isCollections: "", //收藏状态
      c_img: require("@/assets/img/shoucang_icon.png"),
      c_img1: require("@/assets/img/shoucang_icon1.png"),
      evidential_materials:[],      //证据材料
       C_Progress: {}, //投诉进展
      A_Progress: [],
      radio: "1",
      radio1: "0",
      opinion_ipt: "",
      user_uid: "", //登录带的uid
    };
  },
  mounted() {
    var that = this;
    let tokens = localStorage.getItem("token");
    let user_uid = localStorage.getItem("uid");
    that.user_uid = user_uid;
    that.tokens = tokens;
    // 接收传过来的Id
    that.Xq_Id = that.$route.query.id;
    that.Complaint_details();
    that.comment_list();
    that.give_up1(); //点赞状态
    that.Collections(); //收藏状态
    that.progress(); //投诉进度
  },

  methods: {
    Go_HotBrands(id){
this.$router.push({
          name: "BrandHome",
          params: { id: id },
        });
    },
    // 投诉进度
    progress() {
      var that = this;
      Progress({ complaint_id: that.Xq_Id, }).then((res) => {
        //console.log(res);
        that.C_Progress = res.data.data;
        that.A_Progress = res.data.data.publish.reverse();
      });
    },
    // 发送
    opinion_send() {
      var that = this;
      //console.log(this.radio);
      //console.log(this.radio1);
      //console.log(this.opinion_ipt);
      //console.log(this.C_Progress.id);
      if (this.opinion_ipt == "") {
        that.$message.error("输入框不能为空");
      } else {
        Progress_reply({
          ce_id: this.C_Progress.id,
          is_approval: this.radio,
          is_open: this.radio1,
          content: this.opinion_ipt,
        }).then((res) => {
          //console.log(res);
          that.$message.success("发送成功");
          that.reload();
        });
      }
    },
    G_login() {
      this.$router.push({ name: "login" });
    },
    Complaint_details() {
      let that = this;
      let tokens = localStorage.getItem("token");
      if (tokens) {
        complaint_details({ complaint_id: that.Xq_Id ,key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),}).then((res) => {
          //console.log(res.data);
          this.details_list = res.data.data;
          this.details_list_name = res.data.data.complaintBusiness;
          let newevidential_materials=res.data.data.evidential_materials.split(',');
          newevidential_materials.pop()
          that.evidential_materials=newevidential_materials
        });
      } else {
        Axios({
          method: "get",
          url: "https://tsadmin.qudong.com/api/complaint_details",
          params: {
            complaint_id: that.Xq_Id,
          },
        }).then((res) => {
          //console.log(res.data);
          this.details_list = res.data.data;
          this.details_list_name = res.data.data.complaintBusiness;
        });
      }
    },
    comment_list() {
      let tokens = localStorage.getItem("token");
      if (tokens) {
        let that = this;
        //console.log(that.Xq_Id);
        comment_list({ complaint_id: that.Xq_Id }).then((res) => {
          //console.log(res.data.data);
          that.comment_lists = res.data.data.data;
        });
      }
    },
    // // 去相关投诉
    // Related_complaints(complaint_business_id) {
    //   let that = this;
    //   let tokens = localStorage.getItem("token");
    //   if (tokens) {
    //     this.$router.push({
    //       name: "RelatedComplaints",
    //       params: { id: that.Xq_Id, Obid: complaint_business_id },
    //     });
    //   } else {
    //     layer.confirm(
    //       "您未登录,是否前往登录页面？",
    //       {
    //         btn: ["确认", "取消"],
    //       },
    //       function () {
    //         location.href = "/login";
    //       },
    //       function () {
    //         // 按钮2的事件
    //       }
    //     );
    //   }
    // },
    descInput() {
      //console.log(this.msg.length);
      this.remnant = this.msg.length;
    },
    descInputs() {
      //console.log(this.msgs.length);
      this.remnants = this.msgs.length;
    },
    // 弹出回复
    Eject_Comments(index, id, userinfo) {
      //console.log(id);
      //console.log(index);
      //console.log(this.hf_cm);
      //console.log(userinfo);
      if (this.hf_cm == index) {
        this.hf_cm = -1;
      } else {
        this.hf_cm = index;
      }
    },
    success(m) {
      layer.msg(m, { icon: 1 });
    },
    error(m) {
      layer.msg(m, { icon: 0 });
    },

    Reply() {
      var that = this;
      instantly_message({ complaint_id: that.Xq_Id, content: that.msg }).then(
        (res) => {
          //console.log(res);
          that.$message.success(res.data.msg);
          // 刷新当前页面
          that.reload();
        }
      );
    },
    // 点赞
    toCollect() {
      var that = this;
      let token = localStorage.getItem("token");
      if (token) {
        give_up({ complaint_id: that.Xq_Id }).then((res) => {
          //console.log(res);
          let msg = res.data.msg;
          if (res.data.code == 200) {
            that.$message.success(msg);
            that.details_list.like_num = that.details_list.like_num + 1;
          } else {
            that.$message.warning(msg);
            that.details_list.like_num = that.details_list.like_num - 1;
          }
          that.collection = !that.collection;
        });
      } else {
        that.$message.error("请您登录以后点赞");
      }
    },
    // 点赞状态
    give_up1() {
      var that = this;
      give_ups({ complaint_id: that.Xq_Id, type: 1 }).then((res) => {
        //console.log(res.data.status);
        that.isgive_ups = res.data.status;
      });
    },
    // 收藏
    toCollect1() {
      var that = this;
      let token = localStorage.getItem("token");
      if (token) {
        Collection({ complaint_id: that.Xq_Id }).then((res) => {
          //console.log(res);
          let msg = res.data.msg;
          if (res.data.code == 200) {
            that.$message.success(msg);
          } else {
            that.$message.warning(msg);
          }
          that.collection1 = !that.collection1;
        });
      } else {
        that.$message.error("请您登录以后收藏");
      }
    },
    // 收藏状态
    Collections() {
      var that = this;
      give_ups({ complaint_id: that.Xq_Id, type: 2 }).then((res) => {
        //console.log(res.data.status);
        that.isCollections = res.data.status;
      });
    },
  },
};
</script>

<style scoped>
.Margin {
  margin-left: 20px;
}
.details_list {
  width: 920px;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}
.crumbs {
  background: #fff;
  padding: 20px 0 10px 20px;
}
.details_list_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.details_list_top_title {
  font-size: 19px;
  font-weight: 550;
}
.Related_complaints {
  color: #999999;
  cursor: pointer;
}
.details_list_content {
  width: 100%;
  display: flex;

  justify-content: space-between;
}
.details_list_content_left {
  width: 65%;
}
.details_list_content_left_ct {
  margin-bottom: 6px;
}
.details_list_content_right {
  width: 35%;
  height: 50px;
}
.details_list_content_right img {
  width: 131px;
  height: 116px;
  margin-left: 50px;
}
.details_list_bottom_box {
  display: flex;
  align-items: center;
}
.details_list_bottom_box img {
  width: 17px;
  height: 17px;
  margin-right: 3px;
  cursor: pointer;
}
.details_list_bottom_box div {
  margin-top: 3px;
  margin-right: 15px;
}
.details_list_bottom {
  display: flex;
}
.fenxiang img {
  width: 17px;
  margin-top: 3px;
  height: 17px;
}
/* 投诉详情 */
.Complaint_details {
  width: 920px;
  padding: 20px;
  background: #fff;
}
.Complaint_details_title {
  font-size: 19px;
  margin-bottom: 20px;
}
.Complaint_details_content {
  color: #333333;
}
/* 投诉进度 */
.Complaint_progress {
  margin-top: 20px;
  width: 920px;
  padding: 20px;
  background: #fff;
  border-bottom: 1px solid #ccc;
}
.Complaint_progress_title {
  font-size: 19px;
}
.Complaint_progress_bottom {
  width: 920px;
  padding: 20px;
  background: #fff;
}
.Complaint_progress_bottom_box {
  border-left: 1px solid #1f72d9;
  padding-left: 10px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 20px;
}
.box_yuan {
  width: 8px;
  height: 8px;
  background: #1f72d9;
  border-radius: 50%;
  position: absolute;
  left: -5px;
  top: 15px;
}
.Complaint_progress_bottom_box_data {
  margin-bottom: 10px;
}

/* 评论 */
.comment {
  margin-top: 20px;
  width: 920px;
  padding: 20px;
  background: #fff;
  position: relative;
  border-bottom: 1px solid #ccc;
}
.comment_ipt {
  width: 920px;
  height: 35px;
  padding-left: 6px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.Enter_the_number {
  /* position:absolute;
    right:15px;
    top:30px; */
  margin-right: 20px;
  margin-top: 12px;
}
.Reply_box {
  width: 870px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Reply {
  width: 95px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  background: #ccc;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
}
.Reply1 {
  width: 95px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  background: #1f72d9;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
  color: #fff;
}

/* 最新评论 */
.Latest_comments {
  width: 920px;
  padding: 20px;
  background: #fff;
}
.Latest_comments_title {
  font-size: 19px;
  font-weight: 550;
}
.Latest_comments_b {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.Latest_comments_list {
  width: 920px;
  margin-top: 15px;
  display: flex;
}
.Latest_comments_list_left {
  width: 80px;
}
.Latest_comments_list_left img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 30px;
}
.Latest_comments_list_content {
  width: 600px;
}
.Latest_comments_list_content_top {
  display: flex;
  align-items: center;
}
.Latest_comments_list_content_top .names {
  font-size: 16px;
  margin-right: 10px;
}
.Latest_comments_list_content_top .datas {
  font-size: 14px;
}
.Latest_comments_list_content_bottom {
  margin-top: 10px;
}
.Latest_comments_list_right {
  display: flex;
  width: 185px;
  justify-content: flex-end;
}
.Latest_comments_list_right img {
  width: 18px;
  height: 18px;
}
.Latest_comments_list_right img:nth-child(1) {
  margin-right: 30px;
}
.Latest_comments_list_right img:nth-child(2) {
  margin-right: 8px;
}

/* 回复评论 */
.hf_comment {
  margin-top: 20px;
  width: 920px;
  background: #fff;
  position: relative;
}
.hf_comment_ipt {
  width: 920px;
  height: 35px;
  padding-left: 6px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.hf_Enter_the_number {
  /* position:absolute;
    right:15px;
    top:30px; */
  text-align: right;
  margin-top: 12px;
  margin-right: 20px;
}
.hf_Reply_box {
  width: 870px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.hf_Reply {
  width: 95px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  background: #ccc;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
}
.hf_Reply1 {
  width: 95px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  background: #1f72d9;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
  color: #fff;
}
.login_zs {
  margin-top: 30px;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
}
/* 证据材料 */
.Material_Science{
    margin-top:20px;
}
.Material_Science_top{
    display:flex;
    align-items: center;
}
.Material_Science_top span{
font-size: 22px;
color: #333333;
}
.Material_Science_top img{
    width:14px;
    height:16px;
    margin-left:5px;
}
.Material_Science_bottom{
    display:flex;
    flex-wrap: wrap;
    padding:10px 120px;
}
.Material_Science_bottom_img{
   width: 160px;
height: 164px;
border-radius: 6px;
margin-right:30px;
object-fit: cover;
margin-bottom:20px;
}
/* 补充的投诉进展 */
.Complaint_progress_bottom_box_xing {
  display: flex;
}
.Complaint_progress_bottom_box_xing img {
  width: 128px;
  height: 18px;
}
.Complaint_progress_bottom_box_opinion {
  display: flex;
  align-items: center;
}
.opinion_send_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.opinion_send_ipt {
  width: 800px;
  height: 40px;
  border: 1px solid #999999;
  border-radius: 5px;
  margin: 20px 0;
  padding-left: 15px;
}
.opinion_send {
  width: 96px;
  height: 30px;
  background: #1f72d9;
  border-radius: 5px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  margin-right: 30px;
  cursor: pointer;
}
</style>